<template>
  <div class="bag-recharge">
    <div class="bankbox">
      <img src="../../assets/images/new/back-icon.png" alt="" @click="bankgo">
      <p class="bankname">钱包充值</p>
    </div>
    <div class="balance-box">
        <div class="gold-box">
            <p class="gold-text">金币余额</p>
            <div class="gold-num">
              {{user.bean ? user.bean : 0}}
            </div>
        </div>
         <div class="gold-box">
             <p class="gold-text">钻石余额</p>
            <div class="gold-num">
              {{user.integral? user.integral : 0}}
            </div>
        </div>
    </div>
    <div class="recharge-box">
        <img src="../../assets/newImages/notice-icon.png" alt="">
        <p>玩家充值为金币，只有在盲盒中获取的饰品进行回收后才会获得钻石。</p>
    </div>

    <div class="wrap-box" v-if="actypeindex===1">
      <div class="pay-title">
        充值方式
      </div>
       <div class="pay-type">
          <div class="payicon-box" :class="{'payicon-box-acicon':acpaytypeindex===item.id}" @click="currentChange(item.id)" v-for="(item, index) in paytypedata" :key="index">
              {{item.type}}
          </div>
      </div>
      <div class="pay-list">
        <div class="pay-item" v-for="(item, i) in priceList" :key="i" :class="priceCurrent == i ? 'pay-item-ac' : ''" @click="priceChange(item, i)">
          <div class="gold">
            <img src="../../assets/newImages/gold.png" alt="">
          </div>
          <div class="gold-num">
            {{ parseInt(item.bean).toFixed(2) }}
          </div>
         
        </div>
      </div>
      <div class="center">
        <div class="check-box" @click="argsStatus1 = !argsStatus1">
           <div class="rem-checkbox">
              <div v-show="argsStatus1" class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
          <div>
             本人已满18周岁且具备完全行动能力，充值即代表同意 
            <span class="privacy" @click="termsPopupShow = true">《条款和条例》</span>
            <span class="privacy" @click="privacyPopupShow = true">《隐私政策》</span>
          </div>
        </div>
        <div class="paytext">支付金额（人民币）约：<p>{{ acprice }}</p>
        </div>
        <div class="paybtnbox">
          <div class="paybtn point" @click="dopay">充值</div>
          <!-- <div class="paytype">
            <div class="payicon point" :class="{'acicon':acpaytypeindex===item.id}" @click="currentChange(item.id)" v-for="item in paytypedata" :key="item.id">
              <img :src="item.img" alt="">
            </div>
          </div> -->
        </div>
        <!-- <div class="keyput point">
          <input type="text" v-model="cdkey" />
          <div class="buybtn" @click="PostCard_recharge">卡密充值</div>
        </div> -->
      </div>
      <van-overlay @mousewheel.prevent.native="() => {}" :show="payurlshow">
        <div class="paybox" :show="payurlshow">
          <div class="titlebox">
            <p>支付订单</p>
            <img class="closeicon point" src="../../assets/images/new/bg7.png" alt="" @click="payClose">
          </div>
          <div class="line"></div>
          <div class="payewmbox">
            <div>
              <div class="ewmbox">
                <img class="payurl" :src="payurl" alt="" />
              </div>
              <div class="typeimg">
                <img v-if="pay_way === 1" class="payicon" src="../../assets/images/about/recharge/zfb.png" alt="" />
                <img v-else-if="pay_way === 2" class="payicon" src="../../assets/images/about/recharge/wxzf.png" alt="" />
              </div>
            </div>

          </div>
          <div style="text-align: center">请截图后打开{{pay_way === 1?'支付宝':'微信'}}扫一扫支付</div>
        </div>

      </van-overlay>

    </div>
    <div class="tablebox" v-if="actypeindex===2">
      <newtable :tableRecordList="tableRecordList4" :tableData="tableData" @pageChange="PickChange">
        <template slot-scope="item">
          <div v-if="item.item === 'created_at'">
            <div style="line-height: 0.2rem">
              {{ (item.data.created_at)?.substring(0,16)}}
            </div>
          </div>
        </template>
      </newtable>

    </div>
     <!-- 协议弹窗 -->
      <van-overlay :lock-scroll="true" :show="termsPopupShow">
        <termsPopup v-if="termsPopupShow" @close="termsPopupShow = false" />
      </van-overlay>
       <!-- 隐私弹窗 -->
      <van-overlay :lock-scroll="true" :show="privacyPopupShow">
        <privacyPopup v-if="privacyPopupShow" @close="privacyPopupShow = false" />
      </van-overlay>
     <Tabbar />
  </div>
</template>

<script>
import { Recharge, Beans, Oderquery, Card_recharge, Recharge_record,Info} from "@/network/api.js";
import { mapState, mapActions, mapMutations} from "vuex";
import { tableData, tableRecordList4 } from "./index.js";
import newtable from '../../components/newtable/index.vue';
import termsPopup from "@/components/common/termsPopup.vue";
import privacyPopup from "@/components/common/privacyPopup.vue";

export default {
  name: "BagRecharge",
  components: {
    newtable,
    termsPopup,
    privacyPopup
  },
  data() {
    return {
      argsStatus1: false,
      termsPopupShow: false,
      privacyPopupShow: false,
      tableData,
      tableRecordList4,
      curtype: null,
      selectList: [
        {
          label: '全部',
          value: '0',
        },
        {
          label: '开箱',
          value: '1'
        },
        {
          label: '盲盒对战',
          value: '2'
        },
        {
          label: '幸运饰品',
          value: '3'
        },
        {
          label: '装备回收',
          value: '4'
        },
        {
          label: '充值',
          value: '5'
        },
        {
          label: '首充奖励',
          value: '6'
        },
      ],
      isShowFloat: false,//下拉状态
      value: '0',
      typedata: [
        {
          id: 1,
          title: '钱包充值'
        },
        {
          id: 2,
          title: '钱包记录'
        },
      ],
      actypeindex: 1,
      cdkey: "",
      current: 1,
      paytypedata: [
        {
          id: 1,
          type:'充值通道一',
          img: require('../../assets/images/about/recharge/zfb.png')
        },
        {
          id: 2,
          type:'充值通道二',
          img: require('../../assets/images/about/recharge/wxzf.png')
        },
        {
          id: 3,
          type:'充值通道三',
          img: require('../../assets/images/about/recharge/wxzf.png')
        },
      ],
      acpaytypeindex: 1,//选中支付状态
      priceList: [{}],
      priceCurrent: -1, //选中金额
      acprice: 0,
      id: "",
      pay_way: 1, //选择支付方式
      payurl: "",
      payurlshow: false, //支付二维码显示
      order_no: "", //支付订单的状态码
      Timer: null,
      orderInfo: "",
      rechangedata: 0, //是否首充状态
    };
  },
  created() {
    this.getBeans();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    // 钱包记录页码
    PickChange(v) {
      this.page_num = v.page;
      this.getrecord(this.value, this.page_num);
    },
    // 获取到最新的钱包记录
    getrecord(num, page) {
      Recharge_record(num, page).then((res) => {
        this.tableData.list = res.data.data.data;
        this.tableData.total = res.data.data.total;
        this.tableData.pageSize = res.data.data.per_page;
      });
    },
    changeSelect(v) {
      this.value = v.value;
      this.curtype = v.value;
      if (v.value == 0) {
        this.curtype = null;
      }
      this.getrecord(this.curtype);
    },
    typechange(v) {
      this.actypeindex = v;
      if (v == 2) {
        this.getrecord(this.curtype);
      }
    },
    bankgo() {
      window.history.go(-1);
    },
    toTermsPopup(){
      this.$router.push({
        path: '/termsPopup',
      });
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),
    //卡密支付
    PostCard_recharge() {
      Card_recharge(this.cdkey).then((res) => {
        if (res.data?.code === 200) {
          this.$message({
            message: "兑换成功",
            type: "success",
          });
          this.MyInfo();
          this.cdkey = "";
        } else {
          this.$message({
            message: res.data?.message,
            type: "error",
          });
        }
      });
    },
    // 选中充值金额
    priceChange(v, i) {
      this.priceCurrent = i; //样式切换
      this.acprice = v.price; //需要支付金额
      this.id = v.id;
    },
    // 切换充值方式
    currentChange(item) {
      console.log(item,'支付方式')
      this.acpaytypeindex = item;
      if (item === 1) {
        this.pay_way = 1;
      } else if (item === 2) {
        this.pay_way = 2;
      }else if (item === 3) {
        this.PostCard_recharge()
      }

    },
    payClose() {
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      this.payurlshow = false;
    },
    // 当前充值
    dopay() {
      let info = this.user;
      console.log(info, "用户信息");
      if (!this.argsStatus1) {
            this.$message.error(
              "请确认已满18周岁，并同意《用户协议》和《隐私条款》!"
           );
          return;
      }
      if (!info.true_name) {
        this.$message({
          message: "还未实名认证，请认证后操作",
          type: "error",
        });
        this.$router.push("/about");
      } else {
        if (!this.id) {
          this.$message({
            message: "请选择充值金额",
            type: "error",
          });
          return false;
      }
      else {
          Recharge(this.id, this.pay_way).then((res) => {
            if (res.data.code == 200) {
              this.payurlshow = true;
              this.payurl = res.data.data.list.qr_url;
              this.order_no = res.data.data.order_no;
              this.Timer = window.setInterval(() => {
                setTimeout(this.queryAPI(), 0);
              }, 2000);
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        }
      }
    },
    // 获取订单状态api
    queryAPI() {
      Oderquery(this.order_no).then((res) => {
        this.orderInfo = res.data.data;
        if (this.orderInfo.status === 1) {
          this.$message({
            message: "支付成功",
            type: "success",
          });
          if (this.Timer) {
            clearInterval(this.Timer);
          }
          this.payurlshow = false;
          this.MyInfo();
        }
      });
    },
    // 获取到价格列表
    getBeans() {
      Beans().then((res) => {
        let data = res.data.data;
        this.priceList = data;
      });
      let data = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.rechangedata = data.is_recharge;
    },
    gowx() {
      window.location.href = "weixin://";
    },
  },
};
</script>
<style lang="scss" scoped>
.bag-recharge {
  padding: 0.15rem 0.18rem 0.15rem;
  .typebox {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.1rem;
    position: relative;
    .typeitem {
      padding: 0.1rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .balance-box{
    // width: 3.35rem;
    // height: 1.0rem;
    background: #1D1300;
    border: 0.01rem solid #69552D;
    display: flex;
    align-items: center;
    margin-top: 0.18rem;
      .gold-box {
          width: 50%;
          padding: 0.15rem 0.13rem;
          font-family: PingFang SC, PingFang SC;
          .gold-text {
              font-weight: 400;
              font-size: 0.12rem;
              color: #FFFFFF;
              text-align: center;
              font-style: normal;
              margin-bottom: 0.06rem;
          }
          .gold-num {
            width: 1.4rem;
            height: 0.4rem;
            line-height: 0.4rem;
            font-size: 0.16rem;
            text-align: center;
            background: url(../../assets/newImages/gold-box-bg.png) no-repeat;
            background-size: 100% 100%;
            background-position: 0 0;
          }
      }
  }
  .recharge-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.08rem 0 0.16rem 0;

    img {
      width: 0.12rem;
      height: 0.12rem;
    }
    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.1rem;
      color: #BAA272;
      line-height: 0.16rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-left: 0.01rem;
    }

  }
  .pullbox {
    position: absolute;
    right: 0.2rem;
    top: 0.05rem;
    .selectbox {
      .select-box {
        width: 0.89rem;
        height: 0.22rem;
        background: #08080f;
        border: 0.01rem solid #2f2f5e;
        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.22rem;
          padding-left: 0.2rem;
          box-sizing: border-box;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.02rem;
          right: 0.2rem;
          z-index: 1;
          img {
            width: 0.17rem;
            height: 0.16rem;
          }
        }

        .float-box {
          position: absolute;
          left: -0.01rem;
          background: #08080f;
          z-index: 99;
          border: 0.01rem solid #2f2f5e;
          border-top: 0;
          .float-box-item {
            width: 0.87rem;
            height: 0.22rem;
            line-height: 0.22rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            white-space: nowrap;
            overflow: hidden;
          }
          .float-box-item:hover {
            background: #2f2f5e;
            color: #c5bbed;
          }
        }
      }
    }
  }
  .wrap-box {
    width: 3.35rem;
    margin: 0 auto;
    .pay-title {
      font-weight: 600;
      font-size: 0.18rem;
      color: #FFFFFF;
      line-height: 0.25rem;
      text-align: center;
      margin-bottom: 0.12rem;
    }
    .pay-type {
      display: flex;
      width: 3.35rem;
      height: 0.35rem;
      background: #040404;
      .payicon-box {
        width: 0.8rem;
        height: 0.24rem;
        line-height: 0.24rem;
        background: url(../../assets/newImages/paytype-box-bg.png) no-repeat;
        background-size: 100% 100%;
        background-position: 0 0;
        margin: 0.02rem;
        text-align: center;
        font-size: 0.12rem;
        color: #FFFFFF;

      }
      .payicon-box-acicon {
        background: url(../../assets/newImages/paytype-box-bg-active.png) no-repeat;
        background-size: 100% 100%;
        background-position: 0 0;
      }
    }
    .pay-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .pay-item {
           width: 1.06rem;
            height: 0.96rem;
            background: url(/img/gold-choose-bg.4f57d1f3.png) no-repeat;
            background-size: 100% 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 0.18rem;
            color: #ffffff;
            position: relative;
            margin-bottom: 0.1rem;
         .gold {
           width: 0.6rem;
           height: 0.6rem;
           margin: 0.04rem auto;
           img{
             width: 100%;
             height: 100%;
           }
         }
         .gold-num {
          font-weight: 400;
          font-size: 0.12rem;
          color: #FFFFFF;
          text-align: center;
          padding:0.1rem 0;
         }
        .recharge {
          position: absolute;
          top: -0.1rem;
          left: -0.1rem;

          img {
            display: block;
            width: 0.69rem;
          }
        }
      }
      .pay-item-ac {
        background: url(../../assets/newImages/box-active-bg.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .center {
      width: 2.7rem;
      margin: 0 auto;
      .check-box {
        display: flex;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color:#fff;

        .rem-checkbox {
          position: relative;
          width: 0.3rem;
          height: 0.18rem;
          background: #413f3e;
          margin-right: 0.08rem;
          .float-gou-icon {
            position: absolute;
            width: 0.16rem;
            height: 0.16rem;
            left: 0;
            bottom: 0;
            @include bgImg("check-box-active");
          }
        }

        .privacy {
          color: #EAFF00;
        }
        img {
          width: 0.12rem;
          height: 0.12rem;
          margin-right: 0.07rem;
        }
      }
      .paytext {
        margin-top: 0.09rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        p {
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
      }
      .tips {
        margin-top: 0.08rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #efa900;
      }
      .paybtnbox {
        display: flex;
        margin-top: 0.20rem;
        .paybtn {
           width: 2.8rem;
          height: 0.42rem;
          background: url("../../assets/newImages/long-btn-bg.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.18rem;
          color: #312f2d;
          margin: 0 auto;
          font-family: PingFang SC, PingFang SC;
          }
        .paytype {
          display: flex;
          margin-left: 0.16rem;
          .payicon {
            width: 0.3rem;
            height: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.22rem;
              height: 0.22rem;
            }
          }
          .acicon {
            border-radius: 50%;
            background: #08080f;
            border: 0.01rem solid #8585db;
          }
        }
      }
      .keyput {
        margin-top: 0.12rem;
        display: flex;

        input {
          width: 2.27rem;
          height: 0.26rem;
          border: 0.01rem solid #2f2f5e;
          background: #101023;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.1rem;
        }
        .buybtn {
          margin-left: 0.03rem;
          width: 0.7rem;
          height: 0.26rem;
          text-align: center;
          line-height: 0.26rem;
          background: linear-gradient(90deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.12rem;
          color: #ffffff;
        }
      }
    }
  }
  .tablebox {
    width: 3.35rem;
    margin: 0 auto;
    background: #020715;
  }
}
.bankbox {
  width: 3.35rem;
  margin: 0 auto;
  height: 0.44rem;
  display: flex;
  align-items: center;
  background: #020715;
  text-align: center;
  position: relative;
  img {
    position: absolute;
    width: 0.25rem;
    height: 0.14rem;
  }
  .bankname {
    width: 100%;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.16rem;
    color: #ffffff;
  }
}
.paybox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.35rem;
  height: 2.6rem;
  background: #09091f;
  border: 0.01rem solid #5d5d9b;
  .titlebox {
    width: 100%;
    height: 0.38rem;
    line-height: 0.38rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 0.16rem;
    color: #ffffff;
    text-align: center;
    position: relative;
    .closeicon {
      width: 0.12rem;
      height: 0.12rem;
      position: absolute;
      right: 0.15rem;
      top: 0.15rem;
    }
  }
  .line {
    width: 100%;
    height: 0;
    border: 0.01rem solid;
    border-image: linear-gradient(
        90deg,
        rgba(93, 93, 155, 0),
        rgba(93, 93, 155, 1),
        rgba(93, 93, 155, 0)
      )
      1 1;
  }
  .payewmbox {
    width: 100%;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .ewmbox {
      width: 0.98rem;
      height: 0.9rem;
      background: url("../../assets/images/newuser/mb-rechange-payurlbg.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .payurl {
        width: 0.77rem;
        height: 0.77rem;
      }
    }
    .typeimg {
      width: 100%;
      height: 0.61rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.34rem;
        height: 0.34rem;
      }
    }
  }
}
</style>
