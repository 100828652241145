export const tableData = {
    total: 15,
    pageSize: 15,
    current_page: 1,
    list: [
        {}
    ],
};
export const tableRecordList4 = [
    {
        title: "交易时间",
        width: "30%",
        index: "created_at",
        center: "center",
        render: true
    },
    {
        title: "交易类型",
        width: "25%",
        index: "change_type_alias",
        center: "center",
    },
    // {
    //   title: "订单号",
    //   width: "30%",
    //   index: "orderIo",
    //   center: "center",
    // },
    {
        title: "变动金额",
        width: "25%",
        index: "bean",
        center: "center",
    },
    {
        title: "变动余额",
        width: "25%",
        index: "final_bean",
        center: "center",
    }
];