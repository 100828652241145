<template>
  <div class="comp-login">
    <CompPopup @close="$emit('close')">
      <div class="form-box">
        <div class="form-title">条款和条例</div>
        <div class="form-row5 flex-c">
          <div class="datapage-box-info">
            <p v-html="pagehtml"></p>
          </div>
        </div>
        <div class="flex-c">
          <div @click="closeFun" class="btn-close flex-c user-sbtn">关闭</div>
        </div>
      </div>
    </CompPopup>
  </div>
</template>

<script>
import { getDetailData, getArticleData } from "@/network/api.js";
export default {
  data() {
    return {
      pagehtml: null,
      title: "",
      ArticleData: [],//信息数据列表
      acindex: null
    };
  },
  created() {
    this.getlist();
    this.gettipsdata();
    this.acindex = this.$route.query.id;
  },
  methods: {
    // 获得隐私信息列表
    gettipsdata() {
      getArticleData().then((res) => {
        this.ArticleData = res.data.data;
      });
    },
    Toarticle(v) {
      this.acindex = v.id;
      this.$router.push({
        path: "/Datapage",
        query: {
          id: v.id,
        },
      });
    },
    bankgo() {
      this.$router.push({
        path: "/About",
      });
    },
    // 根据id获得文章数据
    getlist() {
      getDetailData(4).then((res) => {
        this.title = res.data.data.title;
        this.pagehtml = res.data.data.content;
      });
    },
    closeFun(){
       this.$emit('close');
    }
  },
};
</script>

<style lang="scss">
.comp-login {
  .form-box {
    width: 2.75rem;
    .form-title {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      margin-bottom: 0.16rem;
    }
    .btn-close {
      width: 1.65rem;
      height: 0.52rem;
      font-weight: 600;
      font-size: 0.16rem;
      color: #312f2d;
      margin-bottom: 0.14rem;
      @include bgImg("user-sbtn");
    }
    .datapage-box-info {
     overflow-y: auto;
     max-height: 300px; 
    .title {
          text-align: center;
          font-weight: 500;
          font-size: 0.18rem;
          margin-bottom: 0.16rem;
    }
    p {
      font-size: 0.16rem;
      color: #fff;
      line-height: 1.75em;

      img {
        width: 100% !important;
        height: unset !important;
      }
    }
  }

  }

}
</style>
